/* poppins-regular - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/poppins-v20-devanagari_latin_latin-ext-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/poppins-v20-devanagari_latin_latin-ext-500.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/poppins-v20-devanagari_latin_latin-ext-600.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  padding: 0;
  box-sizing: border-box;
}
.fa-classic,
.fa-regular,
.fa-solid,
.far,
.fas {
  font-family: "Font Awesome 6 Free" !important;
}
html body #root {
  height: 100vh !important;
}
html {
  height: 100vh !important;
}
body {
  background-color: #eaf7f0;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 100vh !important;
}
/* #root {
  height: 100vh !important;
} */
.App {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
}
main {
  flex-grow: 1;
}
nav a {
  color: #fff !important;
}
a,
button {
  cursor: pointer;
}

.leaderboard {
  text-align: center;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 970px;
  max-height: 90px;
  width: 100%;
  height: 90px;
}

.rectangle {
  text-align: center;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 300px;
  max-width: 320px;
  max-height: 300px;
}
.about-unit-sm a {
  color: black !important;
  font-size: 22px !important;
}
