.grammarItem {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}
.grammarContainer {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.unit-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: left;
}
/* .unit img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 18px;
} */
.unit h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.unit table {
  width: 100%;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #000;
  border-collapse: collapse;
  margin: 0 !important;
}
.unit td,
th {
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #000;
}
.unit-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.about-unit {
  width: 100%;
  background: #fff;
  padding: 24.5px 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-unit-st {
  display: flex;
  align-items: center;
  gap: 20px;
}
.about-unit-st p {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8c8b8b;
}
.about-unit-sm {
  display: flex;
  align-items: center;
  gap: 16px;
}
.quiz {
  background: #fff;
  padding: 24px 0;
  border-radius: 18px;
  display: flex;
  justify-content: center;
}
.quiz-start,
.quiz-question {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
.quiz-question {
  padding: 20px 50px;
}
.quiz-start h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}
.quiz-start p {
  font-size: 18px;
  line-height: 27px;
}
.quiz-start span {
  font-size: 16px;
  line-height: 24px;
}
.quiz-btn {
  display: block;
  width: 100%;
  padding: 16px 0;
  border: none;
  border-radius: 18px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background: #ddd;
}
.quiz-start button {
  width: 100%;
  max-width: 316px;
}
.quiz-btn:disabled {
  /* background: #ddd; */
  /* color: #000; */
}
.quiz-btn-right {
  background: #01756c;
  color: #fff;
}
.quiz-btn-wrong {
  background: #da3030;
  color: #fff;
}
.quiz-question p {
  font-size: 16px;
  color: #8c8b8b;
}
.quiz-question h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.quiz-next-btn {
  max-width: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #305cb1;
  color: #fff;
  place-self: flex-end;
}
.quiz-answer,
.answerBtn {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
.quiz-answer p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}
.quiz-filter {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quiz-filter select {
  width: 100%;
  max-width: 174px;
  font-family: "Poppins";
  font-weight: 500;
  background: #f1f4f6;
  border: 1px solid #000000;
  border-radius: 12px;
  padding: 12px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml,<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_155_2670)"><path d="M9.5 3.75L9.5 14.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 9.75L9.5 14.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 9.75L9.5 14.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_155_2670"><rect width="18" height="18" fill="white" transform="translate(18.5) rotate(90)"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-position: right 5px center;
  cursor: pointer;
}
.quiz-answer .quiz-question {
  max-width: 600px;
  border: 1px solid #8c8b8b;
  border-radius: 18px;
}
@media screen and (max-width: 580px) {
  .about-unit {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .quiz-next-btn {
    max-width: 100%;
  }
  .unit-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 420px) {
  .about-unit-st {
    flex-direction: column;
    gap: 10px;
  }
}
