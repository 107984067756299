.profile-header {
  height: 400px;
  width: 80%;
  margin: auto;
}
.avatar-style {
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: fit-content;
  height: fit-content;
  display: flex;
}
.avatar-text {
  margin-bottom: 10px;
}

.main-profile-wrapper {
  padding-top: 50px;
  display: flex;
  width: 60%;
  margin: auto;
  justify-content: space-between;
}

.en_uz_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.divider {
  width: 5px;
  height: 100%;
  background: red;
  margin: 0 30px;
}
