.container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
.search-label {
  width: 100%;
}
.search_inp {
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 20px 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgb(0, 0, 0);
  border-radius: 0 3px 3px 0;
  border: none;
  margin-right: auto;
}
.search_inp::placeholder {
  color: #aaaaaa;
}

.clear_btn {
  background-color: transparent;
  border: none;
  width: 50px;
  cursor: pointer;
  color: #01756c;
  margin-right: 5px;
  height: 45px;
}
.search_btn {
  background-color: white;
  border: none;
  width: 50px;
  cursor: pointer;
  color: #01756c;
  z-index: 1;
  margin-left: -50px;
  height: 45px;
}
.search_con {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 5px;
  border-radius: 100px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.search-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 125px;
  max-width: 847px;
}

.search_add-btn {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  background-color: #01756c;
  border: none;
  border-radius: 50%;
}

.search_sel {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 36px;
  border: none;
  background-color: #01756c;
  color: #fff;
  font-weight: 400;
  border-radius: 100px;
  margin-right: 27px;
  cursor: pointer;
  /* box-shadow: rgba(20, 19, 19, 0.377) 0px 2px 8px 0px; */
}
.search-icon {
  width: 29px;
  height: 20px;
}
.search-mob-icon {
  display: none;
}
.placeholder {
  width: 100%;
  max-width: 606px;
  padding: 35px 0;
  margin: 80px auto;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  background-repeat: no-repeat;
  background-position: center;
}

/* Delete X icon */

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
/* =============== */
.search-mob-btn {
  display: none;
}
.search-label-wrapper {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .search_con {
    max-width: 90%;
  }
}
@media screen and (max-width: 485px) {
  .search_con {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: transparent;
    box-shadow: none;
  }
  .search_sel {
    padding: 0;
    margin: 0;
    gap: 26px;
    background: transparent;
    color: #000;
  }
  .search_sel span {
    padding: 12px 38px;
    background: #fff;
    border-radius: 12px;
  }
  .search-label {
    position: relative;
    width: 100%;
    padding-left: 24px;
    border-radius: 68px;
    background: #fff;
  }
  .search-label-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .search-mob-btn {
    width: 45px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #ffffff;
    background-color: #01756c;
    border: none;
    border-radius: 50%;
    font-size: 20px;
  }
  .search_inp {
    width: 80%;
    padding: 19px 0;
    margin: 0;
  }
  .search-mob-icon {
    box-sizing: content-box;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 13px;
    background: #01756c;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    z-index: 1;
  }
  .clear_btn {
    display: none;
  }
  .search-icon {
    display: none;
  }
  .placeholder {
    font-size: 16px;
    line-height: 24px;
    background-size: 80%;
  }
  .search_add-btn {
    display: none;
    font-size: 60px;
  }
}
