.grammar-container {
  width: 100%;
  max-width: 1280px;
  padding: 40px 80px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.grammar-container h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.grammar-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.grammar-item {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background: linear-gradient(
    90deg,
    rgba(213, 219, 242, 0.5) 0%,
    rgba(203, 253, 244, 0.8) 100%
  );
  border-radius: 32px;
  gap: 16px;
}
.grammar-item-line {
  width: 100%;
  height: 1px;
  border: none;
  background: #61878c;
}
@media screen and (max-width: 720px) {
  .grammar-container {
    padding: 30px;
  }
}
@media screen and (max-width: 480px) {
  .grammar-container {
    padding: 16px;
  }
  .grammar-list {
    gap: 20px;
  }
}
