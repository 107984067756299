.auth-modal-header {
  display: flex;
  padding-top: 20px;
  justify-content: center;
  gap: 20px;
}
.seperator {
  border: 1px solid #49494962;
}

.auth-modal-header h3 {
  cursor: pointer;
}

.auth-modal-header .active {
  color: #01756c;
}
.auth-modal-body {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}
.auth-button {
  width: 100%;
  height: 45px;
  margin: 17px 0;
  background: #01756c;
  color: white;
  border-radius: 8px;
  border: none;
}
.google-button {
  color: black;
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
