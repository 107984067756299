.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.react-pdf__Page {
  font-size: 10px;
}

.react-pdf__Page__textContent {
  color: transparent;
  opacity: 0.5;
}

.react-pdf__Page__textContent ::selection {
  background: #0000ff;
}

.react-quiz-container {
  margin: 0 auto;
  text-align: center;
}
.react-quiz-container h2:first-child {
  display: none;
  float: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.questionWrapper h2 {
  display: block !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.questionWrapperBody div:nth-child(2) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8c8b8b;
}
.questionWrapperBody h3 {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #000;
  margin-top: 10px;
}
.quiz-result-filter {
  border: none !important;
  background: none !important;
}
.quiz-result-filter select {
  height: auto !important;
  width: 100% !important;
  max-width: 174px !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  background: #f1f4f6 !important;
  border: 1px solid #000000 !important;
  border-radius: 12px !important;
  padding: 12px !important;
  outline: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: url('data:image/svg+xml,<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_155_2670)"><path d="M9.5 3.75L9.5 14.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 9.75L9.5 14.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 9.75L9.5 14.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_155_2670"><rect width="18" height="18" fill="white" transform="translate(18.5) rotate(90)"/></clipPath></defs></svg>') !important;
  background-repeat: no-repeat !important;
  background-position: right 5px center !important;
  cursor: pointer !important;
}
.answerBtn {
  display: block !important;
  width: 100% !important;
  max-width: 500px !important;
  padding: 16px 0 !important;
  border: none !important;
  border-radius: 18px !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
}
.startQuizBtn {
  display: block !important;
  width: 100% !important;
  max-width: 316px !important;
  padding: 16px 0 !important;
  border: none !important;
  border-radius: 18px !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  background: #01756c !important;
  color: #fff !important;
  margin: 0 auto !important;
}

.correct {
  background: #01756c !important;
  color: #fff !important;
}
.incorrect {
  background: #da3030 !important;
  color: #fff !important;
}
.questionBtnContainer {
  display: flex !important;
  justify-content: flex-end !important;
}
.nextQuestionBtn {
  max-width: 205px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background: #305cb1 !important;
  color: #fff !important;
  border-radius: 18px !important;
}
.grammarContainer .container {
  width: 100%;
  background: #fff;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #000;
  font-family: "Poppins";
}
.result-answer-wrapper {
  border: 1px solid #8c8b8b !important;
  border-radius: 18px !important;
}
.result-answer-wrapper h3 {
  background: inherit !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 36px !important;
  color: #000000 !important;
}
.result-answer-wrapper .tag-container {
  display: none !important;
}
.title {
  display: block !important;
}

.title h3,
.title h1,
.title p {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 48px !important;
  text-align: left !important;
}
.title .unit {
  float: left;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 15px;
}
.unit-card {
  margin-top: 0 !important;
}

@media screen and (max-width: 680px) {
  .react-quiz-container {
    max-width: 90% !important;
  }
  .questionWrapper {
    /* max-width: 90% !important; */
    margin: 0 auto !important;
  }
  .answerBtn {
    max-width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .questionBtnContainer {
    justify-content: center !important;
  }
  .nextQuestionBtn {
    max-width: 80% !important;
    padding: 16px 0 !important;
  }
  .questionWrapperBody h3 {
    width: 80% !important;
    margin: 0 auto !important;
  }
}


.tag-container {
  display: none;
}