.result {
  width: 60%;
  min-height: 150px;
  margin: 80px auto;
  padding: 18px 32px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  background-color: #fff;
  position: relative;
  border-radius: 18px;
}
.search_btn_search {
  margin-left: auto;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #01756c;
  border: none;
  border-radius: 50%;
  color: #fff !important;
}
.result h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}
.result i {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8c8b8b;
}
.resultSound {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #8c8b8b;
}
.result div svg {
  width: 24px;
  height: 24px;
}

.result404 {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 66px;
}

.con {
  display: flex;
  flex-direction: column;
  position: relative;
}
.con h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}
.transc {
  z-index: 999;
  margin: 4px 0 10px 0;
  font-size: 14px;
}
.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* text-align: center; */
  color: #000000;
  background-repeat: no-repeat;
  background-size: 100%;
}
.description b {
  z-index: 999;
  margin-top: 16px;
}

@media screen and (max-width: 880px) {
  .result404 {
    flex-direction: column;
    gap: 10px;
  }
  .result404 img {
    width: 144px;
  }
}
@media screen and (max-width: 806px) {
  .result {
    width: 65%;
  }
  .con {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 706px) {
  .result {
    width: 85%;
  }
  .description {
    padding: 0px;
  }
  .con {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 606px) {
  .con {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 280px;
  }
}
