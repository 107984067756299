.grammar-item-title {
  display: flex;
  align-items: center;
}
.grammar-item-title span {
  display: block;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 50%;
  background: #01756c;
  /* font-family: "Gosha Sans"; */
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.grammar-item-title p {
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-right: auto;
}
.grammar-item-title button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: #01756c;
}
.grammar-item-title button svg {
  zoom: 1.3;
}
.grammar-item-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.grammar-item-links div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 5px 20px;
  border-bottom: 1px solid #01756c;
}
.grammar-item-links p {
  font-size: 18px;
  line-height: 27px;
  color: #000;
}
.grammar-item-links a {
  text-decoration: none;
  display: flex;
  color: #01756c;
}
.grammar-item-links div svg {
  zoom: 2;
}
@media screen and (max-width: 480px) {
  .grammar-item-title p {
    font-size: 16px;
    line-height: 24px;
  }
}
