#article {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.4;
}

#article table {
    border-collapse: collapse;
}

/* Apply a default padding if legacy cellpadding attribute is missing */
#article table:not([cellpadding]) th,
#article table:not([cellpadding]) td {
    padding: 0.4rem;
}

/* Set default table styles if a table has a positive border attribute
     and no inline css */
#article table[border]:not([border="0"]):not([style*="border-width"]) th,
#article table[border]:not([border="0"]):not([style*="border-width"]) td {
    border-width: 1px;
}

/* Set default table styles if a table has a positive border attribute
     and no inline css */
#article table[border]:not([border="0"]):not([style*="border-style"]) th,
#article table[border]:not([border="0"]):not([style*="border-style"]) td {
    border-style: solid;
}

/* Set default table styles if a table has a positive border attribute
     and no inline css */
#article table[border]:not([border="0"]):not([style*="border-color"]) th,
#article table[border]:not([border="0"]):not([style*="border-color"]) td {
    border-color: #ccc;
}

#article figure {
    display: table;
    margin: 1rem auto;
}

#article figure figcaption {
    color: #999;
    display: block;
    margin-top: 0.25rem;
    text-align: center;
}

#article hr {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0 0 0;
}

#article code {
    background-color: #e8e8e8;
    border-radius: 3px;
    padding: 0.1rem 0.2rem;
}

#article .mce-content-body:not([dir=rtl]) blockquote {
    border-left: 2px solid #ccc;
    margin-left: 1.5rem;
    padding-left: 1rem;
}

#article .mce-content-body[dir=rtl] blockquote {
    border-right: 2px solid #ccc;
    margin-right: 1.5rem;
    padding-right: 1rem;
}

#article img {
    max-width: 100%;
    height: auto;
}