

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  margin-top: 67px;
  margin-bottom: 80px;
}
.container img {
  margin-bottom: 30px;
}
.container h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}
@media screen and (max-width: 520px) {
  .container img {
    width: 90%;
  }
  .container h3 {
    font-size: 22px;
    line-height: 36px;
  }
}
