.navbar {
  background-color: #01756c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 1.2rem;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  max-width: 1500px;
}

.nav-logo {
  display: flex;
  color: #54b5df;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  margin-right: auto;
}

.nav-menu {
  list-style: none;
}
.nav-menu-div {
  display: flex;

  text-align: center;
}
.nav-links {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  padding: 0 1.2rem;
  gap: 9.5px;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1.2rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #89c2d9;
}

.nav-item .active {
  color: #89c2d9;
  border: 1px solid #89c2d9;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 1080px) {
  .nav-container {
    justify-content: space-between;
    padding-right: 20px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 100px;
    left: -110%;
    bottom: 110px;
    opacity: 1;
    transition: all 0s ease;
  }
  .nav-logo {
    margin-right: 0;
  }

  .nav-menu.active {
    height: 100%;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 2;
  }
  .nav-item .active {
    color: #89c2d9;
    border: none;
  }
  .nav-menu.active footer {
    background: transparent;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    /* transform: translate(-100%, 60%); */
    font-size: 1.8rem;
    cursor: pointer;
    color: #89c2d9;
  }
  .nav-menu-div {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .nav-menu {
    bottom: 120px;
  }
}
